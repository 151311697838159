import React, { useState, useCallback } from 'react';
import Card1 from './Card1';
import Card2 from './Card2';
import axios from 'axios';
import styles from './MainContent.Schedule.module.css';

function MainContent() {
  const [data, setData] = useState([]); // This will hold the available rows for Card2
  const [selectedCountryCode, setSelectedCountryCode] = useState(''); // Country code selected in Card1
  const [selectedRowIds, setSelectedRowIds] = useState([]); // Store selected row IDs from Card2
  const [selectedProxyTemplate, setSelectedProxyTemplate] = useState(''); // Store the selected proxy template

  // Function to fetch available rows based on the selected country code
  const fetchAvailableRows = useCallback(async (countryCode) => {
    try {
      const response = await axios.get(`/api/schedule/rows/available?countryCode=${countryCode}`);
      setData(response.data.availableRows); // Update Card2 with available rows
    } catch (error) {
      console.error('Error fetching available rows', error);
      setData([]); // Clear data if there is an error
    }
  }, []);

  // Handle country code selection in Card1 and fetch the available rows for that country
  const handleCountryCodeChange = useCallback((countryCode) => {
    setSelectedCountryCode(countryCode); // Set the selected country code
    fetchAvailableRows(countryCode); // Fetch available rows for the selected country code
  }, [fetchAvailableRows]);

  // Handle the update of rows in Card2
  const handleUpdateRows = useCallback((availableRows) => {
    setData(availableRows); // Update Card2 with the available rows from Card1
  }, []);

  // Handle the row selection update from Card2
  const handleRowSelection = useCallback((selectedRows) => {
    setSelectedRowIds(selectedRows); // Update selected rows in MainContent
  }, []);

  // Handle the proxy template selection from Card1
  const handleProxyTemplateChange = useCallback((proxyTemplate) => {
    setSelectedProxyTemplate(proxyTemplate); // Update selected proxy template
    console.log('Updated Proxy Template in MainContent:', proxyTemplate);
  }, []);

  // Handle the assignment of proxies and user agents from Card1
  const handleAssignedProxiesUpdate = useCallback(async () => {
    if (selectedRowIds.length === 0) {
      console.error('No rows selected');
      return;
    }

    if (!selectedProxyTemplate) {
      console.error('No proxy template selected');
      return;
    }

    try {
      console.log('Using Proxy Template:', selectedProxyTemplate);

      const response = await axios.post('/api/proxy/assign-rows', {
        selectedProxyTemplate, // Use the selected proxy template (smartproxy or oxylabs)
        selectedCountryCode,   // The country code selected in Card1
        rowIds: selectedRowIds  // Array of selected row IDs
      });

      const assignedProxies = response.data.assignedProxies;

      // Update the rows in Card2 with the assigned proxies and user agents
      const updatedRows = data.map((row) => {
        const assigned = assignedProxies.find(proxy => proxy.rowId === row._id);
        if (assigned) {
          return {
            ...row,
            proxy: assigned.proxyUrl,
            userAgent: assigned.userAgent // Add or update the user agent
          };
        }
        return row;
      });

      setData(updatedRows); // Update Card2 with assigned proxies
    } catch (error) {
      console.error('Error assigning proxies and user agents', error);
    }
  }, [data, selectedCountryCode, selectedRowIds, selectedProxyTemplate]);

  return (
    <div className={styles.mainContent}>
      <div className={styles.card1Container}>
        <Card1
          onCountryCodeChange={handleCountryCodeChange}
          onUpdateRows={handleUpdateRows}
          selectedRows={selectedRowIds} // Pass selected rows to Card1
          onAssignedProxiesUpdate={handleAssignedProxiesUpdate} // Handle the assignment from the button in Card1
          onProxyTemplateChange={handleProxyTemplateChange} // Handle proxy template selection
        />
      </div>
      <div className={styles.card2Container}>
        <Card2 
          data={data} 
          onRowSelection={handleRowSelection} // Track selected rows in Card2
        />
      </div>
    </div>
  );
}

export default MainContent;
