import React, { useState, useEffect } from "react";
import Card1 from "./Card1.l2"; // Import Card1 for date range controls
import Card2 from "./Card2.l2"; // Import Card2 for displaying leads
import styles from "./MainContent.l2.module.css";
import { fetchNewLeads } from "../utils/Trackingapi"; // Import the fetchNewLeads API function

function MainContent() {
  const [dateRange, setDateRange] = useState("today");
  const [leads, setLeads] = useState([]); // Initialize as an empty array
  const [selectedColumns, setSelectedColumns] = useState([
    "Name",
    "Email",
    "SignupDate",
  ]); // Example columns for display

  // Fetch leads based on the selected date range
  const getLeadsData = async () => {
    try {
      const data = await fetchNewLeads(dateRange); // Fetch leads based on date range
      setLeads(Array.isArray(data) ? data : []); // Ensure data is an array
    } catch (error) {
      console.error("Error fetching leads:", error);
      setLeads([]); // Set to an empty array if there's an error
    }
  };

  // Fetch leads whenever the date range changes
  useEffect(() => {
    getLeadsData();
  }, [dateRange]);

  return (
    <div className={styles.mainContent}>
      {/* Card 1 - Date range controls */}
      <div className={styles.card}>
        <Card1 dateRange={dateRange} setDateRange={setDateRange} />
      </div>

      {/* Card 2 - Leads Display */}
      <div className={styles.card}>
        <Card2 leads={leads} selectedColumns={selectedColumns} />
      </div>
    </div>
  );
}

export default MainContent;
