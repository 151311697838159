import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import styles from './Card2.Schedule.module.css';

function Card2({ data, onRowSelection }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalData, setModalData] = useState(null); // State for the modal data
  const [isModalOpen, setIsModalOpen] = useState(false); // State to toggle modal visibility

  // Reset selection when data changes
  useEffect(() => {
    setSelectedRows([]);
    setSelectAll(false);
  }, [data]);

  // Handle individual row selection
  const handleRowSelection = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];

    setSelectedRows(updatedSelectedRows);
    onRowSelection(updatedSelectedRows);
  };

  // Handle select all
  const handleSelectAll = () => {
    const updatedRows = selectAll ? [] : data.map(row => row._id);
    setSelectedRows(updatedRows);
    setSelectAll(!selectAll);
    onRowSelection(updatedRows);
  };

  // Function to open the modal with the row's proxy and user agent
  const openModal = (row) => {
    setModalData({
      proxyUrl: row.proxy,
      userAgent: row.userAgent
    });
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  // Define the columns based on the actual data structure
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
        ),
        accessor: 'select',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedRows.includes(row.original._id)}
            onChange={() => handleRowSelection(row.original._id)}
          />
        ),
      },
      { Header: 'First Name', accessor: 'firstName' },
      { Header: 'Last Name', accessor: 'lastName' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Country Code', accessor: 'countryCode' },
      { Header: 'Phone Number', accessor: 'phoneNumber' },
      {
        Header: 'User Agent',
        accessor: 'userAgent',
        Cell: ({ row }) => (
          row.original.userAgent ? (
            <button
              className={styles.viewButton}
              onClick={() => openModal(row.original)}
              style={{ backgroundColor: 'green', color: 'white' }}
            >
              View
            </button>
          ) : (
            <span>Not Assigned</span> // Show this when the user agent is not assigned
          )
        ),
      },
      {
        Header: 'Proxy',
        accessor: 'proxy',
        Cell: ({ row }) => (
          row.original.proxy ? (
            <button
              className={styles.viewButton}
              onClick={() => openModal(row.original)}
              style={{ backgroundColor: 'green', color: 'white' }}
            >
              View
            </button>
          ) : (
            <span>Not Assigned</span> // Show this when the proxy is not assigned
          )
        ),
      },
    ],
    [selectedRows, selectAll]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <div className={styles.card2}>
      <div className={styles.tableContainer}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className={styles.emptyMessage}>
                  Please select a country code to display available rows.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {isModalOpen && modalData && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Proxy & User Agent Info</h3>
            <p><strong>Proxy URL:</strong> {modalData.proxyUrl}</p>
            <p><strong>User Agent:</strong> {modalData.userAgent}</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Card2;
