import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from '../../utils/axiosConfig';
import styles from './Card1.uas.module.css';

function Card1({ onUpload }) {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('');

  const handleDrop = useCallback(
    (acceptedFiles) => {
      // Ensure a file is selected before proceeding
      if (acceptedFiles.length === 0) {
        setFeedbackMessage('No file selected');
        setFeedbackColor('red');
        setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
        return;
      }

      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(`${process.env.REACT_APP_API_URL}/api/user-agents/upload`, formData)
        .then((response) => {
          onUpload(response.data);
          setFeedbackMessage('User agents uploaded successfully!');
          setFeedbackColor('green');
          setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
        })
        .catch((error) => {
          setFeedbackMessage(`Error uploading file: ${error.response?.data?.message || 'Server error'}`);
          setFeedbackColor('red');
          setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
        });
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: '.txt, .csv',  // Accept only text or CSV files
  });

  return (
    <div className={styles.card1}>
      {feedbackMessage && (
        <div className={styles.feedback} style={{ color: feedbackColor }}>
          {feedbackMessage}
        </div>
      )}
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop a .txt or .csv file here, or click to select one</p>
      </div>
    </div>
  );
}

export default Card1;
