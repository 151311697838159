import React, { useState, useMemo, useCallback } from 'react';
import { useTable } from 'react-table';
import Modal from 'react-modal';
import styles from './Card2.uas.module.css';

Modal.setAppElement('#root');

function Card2({ data }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  // Open modal with selected agent details
  const handleViewAgent = useCallback((agent) => {
    setSelectedAgent(agent);
    setModalIsOpen(true);
  }, []);

  // Close modal and reset selected agent
  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
    setSelectedAgent(null);
  }, []);

  // Define table columns using useMemo to prevent unnecessary re-renders
  const columns = useMemo(() => [
    {
      Header: 'User Agent',
      accessor: 'userAgent'  // The field name in the data
    },
    {
      Header: 'Used',
      accessor: 'used',
      Cell: ({ value }) => (value ? 'Yes' : 'No')  // Show "Yes" or "No" based on boolean value
    },
    {
      Header: 'Link Used',
      accessor: 'linkUsed'  // The field name for the link used
    },
    {
      Header: 'Details',
      accessor: 'details',
      Cell: ({ row }) => (
        <button onClick={() => handleViewAgent(row.original)}>View</button>  // Open modal on button click
      )
    }
  ], [handleViewAgent]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: Array.isArray(data) ? data : []  // Ensure data is an array
  });

  return (
    <div className={styles.card2}>
      <div className={styles.tableContainer}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length === 0 ? (
              <tr>
                <td colSpan={4} style={{ textAlign: 'center' }}>No user agents found</td>
              </tr>
            ) : (
              rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="User Agent Modal"
        className={styles.modal}  // Optional: Style your modal
      >
        {selectedAgent && (
          <div>
            <h2>User Agent Details</h2>
            <p><strong>User Agent:</strong> {selectedAgent.userAgent}</p>
            <p><strong>Used:</strong> {selectedAgent.used ? 'Yes' : 'No'}</p>
            <p><strong>Link Used:</strong> {selectedAgent.linkUsed}</p>
          </div>
        )}
        <button onClick={handleCloseModal}>Close</button>
      </Modal>
    </div>
  );
}

export default Card2;
