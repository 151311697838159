import React, { useEffect, useState } from 'react';
import styles from './Card2.ps.module.css';
import axios from '../../utils/axiosConfig';  // Use the custom axios instance

function Card2() {
  const [smartproxy, setSmartproxy] = useState(null);
  const [oxylabs, setOxylabs] = useState(null);
  const [geoDetails, setGeoDetails] = useState([]);
  const [error, setError] = useState('');
  const [showSmartproxyCredentials, setShowSmartproxyCredentials] = useState(false);
  const [showOxylabsCredentials, setShowOxylabsCredentials] = useState(false);
  const [pingedIps, setPingedIps] = useState({});  // Store pinged IP addresses
  const pollingInterval = 5000; // Set polling interval (e.g., 5 seconds)

  // Fetch saved credentials and proxy configuration
  const fetchSettings = async () => {
    try {
      const settingsResponse = await axios.get('/api/proxy/get-settings');
      const settingsData = settingsResponse.data;

      setSmartproxy(settingsData.smartproxy);
      setOxylabs(settingsData.oxylabs);
      setGeoDetails(Array.isArray(settingsData.geoDetails) ? settingsData.geoDetails : []);
    } catch (error) {
      console.error('Error fetching proxy settings or configuration:', error);
      setError('Error fetching proxy settings or configuration.');
    }
  };

  // Initial fetch when component mounts
  useEffect(() => {
    fetchSettings();

    // Set up polling to update every X seconds
    const intervalId = setInterval(fetchSettings, pollingInterval);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Calculate next proxy port based on last proxy, starting port, and port gap
  const calculateNextProxyPort = (lastProxy, startingPort, startingPortGap) => {
    if (lastProxy && lastProxy !== 'N/A') {
      return lastProxy + 1;  // Add 1 to the last used proxy
    }
    return startingPort + startingPortGap;  // Default to starting port + gap
  };

  // Function to construct the proxy URL
  const constructProxyUrl = (provider, geo, nextProxy, username, password) => {
    if (!nextProxy || nextProxy === 'N/A') return 'N/A';
    const geoCode = geo.toLowerCase();  // Assuming geoCode matches the geo (like GB, AU)
    const providerUrl = provider === 'smartproxy' ? 'smartproxy.com' : 'oxylabs.io';  // Use correct provider URLs
    return `http://${username}:${password}@${geoCode}.${providerUrl}:${nextProxy}`;
  };

  // Ping the constructed URL and get the IP address (mocking a ping request)
  const pingProxy = async (url, geo, provider) => {
    try {
      const response = await axios.get(`/api/proxy/ping-proxy?url=${encodeURIComponent(url)}`);  // Mock API to ping proxy
      const ipAddress = response.data.ip;

      setPingedIps((prev) => ({
        ...prev,
        [`${provider}-${geo}`]: ipAddress || 'IP Not Found',
      }));
    } catch (error) {
      console.error('Error pinging proxy:', error);
      setPingedIps((prev) => ({
        ...prev,
        [`${provider}-${geo}`]: 'Error',
      }));
    }
  };

  return (
    <div className={styles.card2}>
      <h2>Geo Proxy Details</h2>
      {error && <p>{error}</p>}
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Geo</th>
              <th>Smartproxy</th>
              <th>Oxylabs</th>
            </tr>
          </thead>
          <tbody>
            {/* First row for credentials */}
            <tr>
              <td><strong>Credentials</strong></td>
              <td>
                <p>
                  <strong>Username:</strong>{' '}
                  {showSmartproxyCredentials ? (smartproxy ? smartproxy.username : 'Not set') : '••••••••'}
                </p>
                <p>
                  <strong>Password:</strong>{' '}{showSmartproxyCredentials ? (smartproxy ? smartproxy.password : 'Not set') : '••••••••'}
                </p>
                <p><strong>Starting Port Gap:</strong> {smartproxy ? smartproxy.startingPortGap : 'Not set'}</p>
                <button onClick={() => setShowSmartproxyCredentials(!showSmartproxyCredentials)}>
                  {showSmartproxyCredentials ? 'Hide' : 'Show'} Credentials
                </button>
              </td>
              <td>
                <p>
                  <strong>Username:</strong>{' '}
                  {showOxylabsCredentials ? (oxylabs ? oxylabs.username : 'Not set') : '••••••••'}
                </p>
                <p>
                  <strong>Password:</strong>{' '}{showOxylabsCredentials ? (oxylabs ? oxylabs.password : 'Not set') : '••••••••'}
                </p>
                <p><strong>Starting Port Gap:</strong> {oxylabs ? oxylabs.startingPortGap : 'Not set'}</p>
                <button onClick={() => setShowOxylabsCredentials(!showOxylabsCredentials)}>
                  {showOxylabsCredentials ? 'Hide' : 'Show'} Credentials
                </button>
              </td>
            </tr>

            {/* Display geo details for Smartproxy and Oxylabs */}
            {geoDetails.length > 0 ? (
              geoDetails.map((detail, index) => {
                const smartproxyNextProxy = calculateNextProxyPort(detail.smartproxy?.lastProxy, 30001, smartproxy?.startingPortGap);
                const oxylabsNextProxy = calculateNextProxyPort(detail.oxylabs?.lastProxy, 40000, oxylabs?.startingPortGap);

                const smartproxyUrl = constructProxyUrl('smartproxy', detail.geo, smartproxyNextProxy, smartproxy?.username, smartproxy?.password);
                const oxylabsUrl = constructProxyUrl('oxylabs', detail.geo, oxylabsNextProxy, oxylabs?.username, oxylabs?.password);

                return (
                  <tr key={index}>
                    <td>{detail.geo}</td> {/* Display geo code like GB, AU, etc. */}

                    {/* Smartproxy Details */}
                    <td>
                      <p><strong>Last Proxy Used:</strong> {detail.smartproxy?.lastProxy || 'N/A'}</p>
                      <p><strong>Next Proxy:</strong> {smartproxyNextProxy || 'N/A'}</p>
                      <p><strong>Proxy URL:</strong> {smartproxyUrl}</p>
                      <p><strong>IP Address:</strong> {pingedIps[`smartproxy-${detail.geo}`] || 'Not Pinged'}</p>
                      <button onClick={() => pingProxy(smartproxyUrl, detail.geo, 'smartproxy')}>
                        Ping Proxy
                      </button>
                    </td>

                    {/* Oxylabs Details */}
                    <td>
                      <p><strong>Last Proxy Used:</strong> {detail.oxylabs?.lastProxy || 'N/A'}</p>
                      <p><strong>Next Proxy:</strong> {oxylabsNextProxy || 'N/A'}</p>
                      <p><strong>Proxy URL:</strong> {oxylabsUrl}</p>
                      <p><strong>IP Address:</strong> {pingedIps[`oxylabs-${detail.geo}`] || 'Not Pinged'}</p>
                      <button onClick={() => pingProxy(oxylabsUrl, detail.geo, 'oxylabs')}>
                        Ping Proxy
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3">No geo details available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Card2;
