import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import styles from './Card2.ul.module.css';

function Card2({ onRowsDeleted }) {
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('');
  const [sortConfig, setSortConfig] = useState(null); // For sorting
  const [selectAll, setSelectAll] = useState(false); // Track select all state

  // Fetch the rows (leads) on component mount
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/schedule/data`)
      .then((response) => setRows(response.data))
      .catch((error) => console.error('Error fetching rows:', error));
  }, []);

  const handleCheckboxChange = (rowId) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowId)
        ? prevSelected.filter((id) => id !== rowId)
        : [...prevSelected, rowId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // If already selected, deselect all rows
      setSelectedRows([]);
    } else {
      // Select all rows
      const allRowIds = rows.map(row => row._id);
      setSelectedRows(allRowIds);
    }
    setSelectAll(!selectAll); // Toggle select all state
  };

  const handleDeleteSelectedRows = () => {
    const selectedRange = selectedRows.join(',');
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/schedule/delete`, {
        data: { selectedRows },
      })
      .then((response) => {
        onRowsDeleted(response.data.data);
        setFeedbackMessage('Selected rows deleted successfully!');
        setFeedbackColor('green');
        setRows((prevRows) => prevRows.filter((row) => !selectedRows.includes(row._id)));
        setSelectedRows([]); // Clear the selected rows
        setTimeout(() => setFeedbackMessage(''), 3000);
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
        setFeedbackMessage(`Error deleting rows: ${errorMessage}`);
        setFeedbackColor('red');
        setTimeout(() => setFeedbackMessage(''), 3000);
      });
  };

  const handleSort = (field) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === field && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: field, direction });

    const sortedRows = [...rows].sort((a, b) => {
      if (a[field] < b[field]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setRows(sortedRows);
  };

  return (
    <div className={styles.card2}>
      {feedbackMessage && (
        <div className={styles.feedback} style={{ color: feedbackColor }}>
          {feedbackMessage}
        </div>
      )}

      {/* Delete button at the top */}
      {selectedRows.length > 0 && (
        <button className={styles.deleteButton} onClick={handleDeleteSelectedRows}>
          Delete Selected Rows
        </button>
      )}

      {/* Scrollable table */}
      <div className={styles.tableContainer}>
        <table className={styles.rowsTable}>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll} // Toggle between checked and unchecked
                  onChange={handleSelectAll}
                />
                
              </th>
              <th onClick={() => handleSort('firstName')}>First Name</th>
              <th onClick={() => handleSort('lastName')}>Last Name</th>
              <th onClick={() => handleSort('email')}>Email</th>
              <th onClick={() => handleSort('countryCode')}>Country Code</th>
              <th onClick={() => handleSort('phoneNumber')}>Phone Number</th>
              <th onClick={() => handleSort('password')}>Password</th>
              <th onClick={() => handleSort('status')}>Status</th>
              <th onClick={() => handleSort('isUsed')}>isUsed</th> {/* Example geo field */}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row._id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(row._id)}
                    onChange={() => handleCheckboxChange(row._id)}
                  />
                </td>
                <td>{row.firstName}</td>
                <td>{row.lastName}</td>
                <td>{row.email}</td>
                <td>{row.countryCode}</td>
                <td>{row.phoneNumber}</td> {/* Phone number */}
                <td>{row.password}</td> {/* Password */}
                <td>{row.status}</td> {/* Status */}
                <td>{row.isUsed ? 'Yes' : 'No'}</td> {/* Display Yes/No for isUsed */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Card2;
