import React, { useEffect, useState, useCallback } from 'react';
import { fetchCRMData, fetchLeadsData } from '../../utils/Trackingapi'; // Only fetchCRMData and fetchLeadsData are needed
import TopMetrics from '../../CRM/TopMetricsCRM';
import CRMList from './CRMList';
import FTDS from '../../FTDS/FTDS';

const CRMComponent = () => {
  const [leads, setLeads] = useState([]);
  const [dateRange, setDateRange] = useState('today'); // Default to 'today'
  const [leadType, setLeadType] = useState(''); // Use state for lead type
  const [selectedColumns, setSelectedColumns] = useState([
    'First Name', 'Last Name', 'Email', 'Phone', 'IP Address', 'Date & Time', 'Country Name', 'API Name', 'Name','Sale Status', 'Lead' ,'FTDS'
  ]);

  // Load initial CRM data from the unified CRM model
  const loadInitialCRMData = useCallback(async () => {
    try {
      const crmData = await fetchCRMData(dateRange, leadType);
      // Initialize leads with a lead flag set to "No" initially
      setLeads(crmData.map(crm => ({ ...crm, lead: 'No' })));
    } catch (error) {
      console.error('Error loading initial CRM data:', error);
    }
  }, [dateRange, leadType]);

  // Load leads data from the unified CRM model and update lead status
  const loadLeadsData = useCallback(async () => {
    try {
      const leadsData = await fetchLeadsData(dateRange, leadType);

      setLeads(prevLeads =>
        prevLeads.map(lead => {
          const updatedLead = leadsData.find(ld => ld.clickId === lead.custom5);
          return updatedLead ? { ...lead, ...updatedLead, lead: 'Yes' } : lead;
        })
      );
    } catch (error) {
      console.error('Error fetching leads data:', error);
    }
  }, [dateRange, leadType]);

  useEffect(() => {
    loadInitialCRMData();
  }, [loadInitialCRMData]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadLeadsData();
    }, 30000); // Fetch leads data every 30 seconds

    return () => clearInterval(interval);
  }, [loadLeadsData]);

  // Handle filter changes and reload CRM data based on new filters
  const handleApplyFilters = (newDateRange, newLeadType, newSelectedColumns) => {
    setDateRange(newDateRange);
    setLeadType(newLeadType);
    setSelectedColumns(newSelectedColumns);
    fetchFilteredCRMData(newDateRange, newLeadType); // Fetch CRM data based on the new filters
  };

  // Fetch filtered CRM data from the unified CRM model
  const fetchFilteredCRMData = async (newDateRange, newLeadType) => {
    try {
      const crmData = await fetchCRMData(newDateRange, newLeadType);
      setLeads(crmData.map(crm => ({ ...crm, lead: 'No' })));
    } catch (error) {
      console.error('Error fetching filtered CRM data:', error);
    }
  };

  return (
    <div>
      <TopMetrics
        dateRange={dateRange}
        setDateRange={setDateRange}
        leadType={leadType}
        setLeadType={setLeadType}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        onApplyFilters={handleApplyFilters}
      />
      <h1>CRM Report</h1>
      <CRMList leads={leads} selectedColumns={selectedColumns} />
    </div>
  );
};

export default CRMComponent;
