import React from 'react';
import Sidebar from '../../dashboard/Sidebar';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import MainContent from './MainContent'; // Import MainContent
import styles from './UserAgentSettings.uas.module.css';

function UserAgentSettings() {
  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="main-content">
        <div className={styles.userAgentSettings}>
          {/* Replace Schedule Your leads with User-Agent Settings */}
          <h2>User-Agent Settings</h2>
          {/* Keep the date */}
          <p>{new Date().toLocaleString()}</p>
        </div>
        {/* Remove the User-Agent options (System Generated and Upload) */}
        <MainContent />
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default UserAgentSettings;
