import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

function Home() {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate('/login');
  };

  const redirectToRegister = () => {
    navigate('/register');
  };

  return (
    <div className="home-page">
      <header className="home-header">
        <div className="header-content">
          <h1>Welcome to Leadsspx </h1>
          <div className="auth-buttons">
            <button onClick={redirectToLogin} className="login-button">Login</button>
            <button onClick={redirectToRegister} className="register-button">Register</button>
          </div>
        </div>
      </header>
      <main className="home-main">
        <section className="intro-section">
          <h2>Discover Amazing Features</h2>
          <p>Explore the best services we offer and join our community today.</p>
        </section>
        <section className="features-section">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>Feature 1: Easy to use platform</li>
            <li>Feature 2: Secure and reliable services</li>
            <li>Feature 3: 24/7 customer support</li>
          </ul>
        </section>
      </main>
      <footer className="home-footer">
        <p>&copy; 2024 Our Website. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
