import React, { useState, useEffect } from 'react';
import Sidebar from '../../dashboard/Sidebar';
import TopMetrics from './TopMetrics';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import styles from './ScheduledTasks.st.module.css';
import axios from '../../utils/axiosConfig';
import { formatDistanceToNow } from 'date-fns'; // Use this for countdown

function ScheduledTasks() {
  const [scheduledTasks, setScheduledTasks] = useState([]);  // Task list from the server
  const [selectedRows, setSelectedRows] = useState([]);  // Track selected rows by index
  const [selectedAll, setSelectedAll] = useState(false);  // Track if all rows are selected

  // Fetch scheduled tasks from the server
  useEffect(() => {
    const fetchScheduledTasks = async () => {
      try {
        const response = await axios.get('/api/schedule/tasks');  // Example API request
        setScheduledTasks(response.data);
      } catch (error) {
        console.error('Error fetching scheduled tasks:', error);
      }
    };
    fetchScheduledTasks();
  }, []);

  // Countdown Timer - dynamically update task start time
  const getCountdown = (startTime) => {
    const start = new Date(startTime);
    return formatDistanceToNow(start, { addSuffix: true }); // Countdown to the start time
  };

  // Handle checkbox click for individual rows
  const handleRowCheckboxClick = (rowNumber) => {
    if (selectedRows.includes(rowNumber)) {
      setSelectedRows(selectedRows.filter(row => row !== rowNumber));  // Deselect the row if already selected
    } else {
      setSelectedRows([...selectedRows, rowNumber]);  // Add row to selected rows
    }
    setSelectedAll(false);  // Uncheck "Select All" if individual row is clicked
  };

  // Handle task execution for the selected rows
  const handleExecuteTask = async () => {
    if (selectedRows.length === 0 && !selectedAll) return; // Ensure that at least one row or all rows are selected
    try {
      const tasksToExecute = selectedAll
        ? scheduledTasks.map(task => task._id)  // Execute all tasks if selected all
        : selectedRows.map(rowNumber => scheduledTasks[rowNumber]._id);  // Execute tasks based on selected rows
      await axios.post('/api/schedule/execute-tasks', { taskIds: tasksToExecute });
      alert('Task(s) executed successfully!');
    } catch (error) {
      console.error('Error executing task:', error);
    }
  };

  // Handle task pause for the selected rows
  const handlePauseTask = async () => {
    if (selectedRows.length === 0 && !selectedAll) return; // Ensure that at least one row or all rows are selected
    try {
      const tasksToPause = selectedAll
        ? scheduledTasks.map(task => task._id)  // Pause all tasks if selected all
        : selectedRows.map(rowNumber => scheduledTasks[rowNumber]._id);  // Pause tasks based on selected rows
      await axios.post('/api/schedule/pause-tasks', { taskIds: tasksToPause });
      alert('Task(s) paused successfully!');
    } catch (error) {
      console.error('Error pausing task:', error);
    }
  };

  // Handle "select all" functionality from the header checkbox
  const handleSelectAll = () => {
    if (!selectedAll) {
      setSelectedAll(true);
      setSelectedRows(scheduledTasks.map((_, index) => index));  // Select all rows
    } else {
      setSelectedAll(false);
      setSelectedRows([]);  // Deselect all rows
    }
  };

  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="top-metrics">
        <TopMetrics />
      </div>
      <div id="main-content">
        <div className={styles.scheduledTasks}>
          <h2>Scheduled Tasks</h2>

          <div className={styles.buttonContainer}>
            {/* Buttons to execute or pause the selected task */}
            <button
              onClick={handleExecuteTask}
              disabled={selectedRows.length === 0 && !selectedAll} // Disable button if no row or all rows are selected
              className={styles.taskButton}
            >
              Execute Task
            </button>
            <button
              onClick={handlePauseTask}
              disabled={selectedRows.length === 0 && !selectedAll} // Disable button if no row or all rows are selected
              className={`${styles.taskButton} ${styles.pauseButton}`}
            >
              Pause Task
            </button>
          </div>

          {scheduledTasks.length > 0 ? (
            <>
              <table className={styles.taskTable}>
                <thead>
                  <tr>
                    <th>
                      <input 
                        type="checkbox" 
                        checked={selectedAll} 
                        onChange={handleSelectAll} 
                      /> {/* Checkbox to select all rows */}
                    </th>
                    <th>#</th>
                    <th>Task Name</th>
                    <th>Status</th>
                    <th>Time to Start</th>
                    <th>Offer Link</th> {/* Offer Link replaces Proxy */}
                    <th>Country Code</th> {/* Country Code replaces User Agent */}
                    <th>Row Range</th> {/* Add new column for Row Range */}
                  </tr>
                </thead>
                <tbody>
                  {scheduledTasks.map((task, index) => (
                    <tr key={task._id} className={selectedRows.includes(index) || selectedAll ? styles.selectedRow : ''}>
                      <td>
                        <input 
                          type="checkbox" 
                          checked={selectedRows.includes(index) || selectedAll} 
                          onChange={() => handleRowCheckboxClick(index)} 
                        />
                      </td>
                      <td>{index + 1}</td> {/* Display row number */}
                      <td>{task.formData.taskName}</td> {/* Task Name */}
                      <td>{task.status}</td> {/* Task Status */}
                      <td>{getCountdown(task.startTime)}</td> {/* Countdown to Start */}
                      <td>{task.formData.offerLink}</td> {/* Offer Link */}
                      <td>{task.formData.countryCode}</td> {/* Country Code */}
                      <td>{task.formData.rowRange}</td> {/* Row Range */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p className={styles.noTasksMessage}>No scheduled tasks found.</p>
          )}
        </div>
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default ScheduledTasks;
