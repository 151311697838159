import React from 'react';
import Sidebar from '../dashboard/Sidebar';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import MainContent from './MainContent'; // Import the new MainContent component
import styles from './Leads2.l2.module.css';

function CRM() {
  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="main-content">
        <MainContent /> {/* Use MainContent here */}
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default CRM;
