import React, { useState, useEffect } from 'react';
import { getCurrentDateTime } from '../../utils/dateTimeUtils';
import styles from './TopMetrics.ul.module.css';

function TopMetrics() {
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.topMetrics}>
      <div className={styles.header}>
        <h2>Upload Your leads</h2> {/* Updated to reflect the Schedule page */}
        <div className={styles.dateTime}>{currentDateTime}</div>
      </div>
   
     
    </div>
  );
}

export default TopMetrics;
