import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';

// Import create components for each template
import CreateAlgo from './Create/CreateAlgo';
import CreateBW from './Create/CreateBW';
import CreateGetLinked from './Create/CreateGetLinked';
import CreateHM from './Create/CreateHM';
import CreateM5 from './Create/CreateM5';
import CreateRoiBoomers from './Create/CreateRoiBoomers';
import CreateTrackbox from './Create/CreateTrackbox';
import CreateViceDealz from './Create/CreateViceDealz';

import styles from './Card2.Networking.module.css';

// Map each template name to its specific create component
const createFormComponents = {
  Algo: CreateAlgo,
  BW: CreateBW,
  GetLinked: CreateGetLinked,
  HM: CreateHM,
  M500: CreateM5,
  RoiBoomers: CreateRoiBoomers,
  Trackbox: CreateTrackbox,
  ViceDeals: CreateViceDealz,
};

function Card2({ selectedItem, onCreateItem, onUpdateItem, onDeleteItem }) {
  const isEditing = Boolean(selectedItem && selectedItem._id);
  const [selectedTemplate, setSelectedTemplate] = useState(selectedItem?.template || null);
  const [formData, setFormData] = useState(selectedItem || {});

  useEffect(() => {
    if (selectedItem) {
      setFormData(selectedItem);
      setSelectedTemplate(selectedItem.template);
    } else {
      resetForm();
    }
  }, [selectedItem]);

  const resetForm = () => {
    setFormData({});
    setSelectedTemplate(null);
  };

  const handleTemplateSelect = (templateName) => {
    setSelectedTemplate(templateName);
    setFormData({
      template: templateName,
      name: '',
      apiKey: '',
      offerName: '',
      offerWebsite: '',
      domainName: '',
      ...(templateName === 'Trackbox' && {
        EndPointURL: '',
        APIName: '',
        pullApiKey: '',
        so: '',
        sub: '',
      }),
      ...(templateName === 'GetLinked' && {
        EndPointURL: '',
        APIName: '',
        custom1: '',
        custom2: '',
        custom3: '',
        custom4: '',
        custom5: '',
      }),
    });
  };

  // Individual submit functions for each template with API endpoint and download
  const handleSubmitTrackbox = async () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/trackbox/networks`;
    await handleNetworkRequest(apiEndpoint, `${formData.APIName || 'trackbox'}_template.zip`);
  };

  const handleSubmitGetLinked = async () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/getlinked/networks`;
    await handleNetworkRequest(apiEndpoint, `${formData.APIName || 'getlinked'}_template.zip`);
  };

  const handleSubmitAlgo = async () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/algo/networks`;
    await handleNetworkRequest(apiEndpoint, `${formData.APIName || 'algo'}_template.zip`);
  };

  const handleSubmitBW = async () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/bluewave/networks`;
    await handleNetworkRequest(apiEndpoint, `${formData.APIName || 'bluewave'}_template.zip`);
  };

  const handleSubmitHM = async () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/hm/networks`;
    await handleNetworkRequest(apiEndpoint, `${formData.APIName || 'hm'}_template.zip`);
  };

  const handleSubmitM500 = async () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/m500/networks`;
    await handleNetworkRequest(apiEndpoint, `${formData.APIName || 'm500'}_template.zip`);
  };

  const handleSubmitRoiBoomers = async () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/roiboomers/networks`;
    await handleNetworkRequest(apiEndpoint, `${formData.APIName || 'roiboomers'}_template.zip`);
  };

  const handleSubmitViceDealz = async () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/vicedeals/networks`;
    await handleNetworkRequest(apiEndpoint, `${formData.APIName || 'vicedeals'}_template.zip`);
  };

  // Function to handle API request and download
  const handleNetworkRequest = async (apiEndpoint, filename) => {
    try {
      const response = await axios.post(apiEndpoint, formData, { responseType: 'blob' });
      handleDownload(response.data, filename);
      onCreateItem(response.data); // Callback to add created item to the list
      resetForm();
    } catch (error) {
      console.error(`Error creating ${selectedTemplate} network:`, error);
    }
  };

  // Function to handle the download of the ZIP file
  const handleDownload = (data, filename) => {
    const blob = new Blob([data], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Main handleSubmit function to dispatch based on selected template
  const handleSubmit = async () => {
    switch (selectedTemplate) {
      case 'Trackbox':
        await handleSubmitTrackbox();
        break;
      case 'GetLinked':
        await handleSubmitGetLinked();
        break;
      case 'Algo':
        await handleSubmitAlgo();
        break;
      case 'BW':
        await handleSubmitBW();
        break;
      case 'HM':
        await handleSubmitHM();
        break;
      case 'M500':
        await handleSubmitM500();
        break;
      case 'RoiBoomers':
        await handleSubmitRoiBoomers();
        break;
      case 'ViceDeals':
        await handleSubmitViceDealz();
        break;
      default:
        console.error("No matching template found for:", selectedTemplate);
    }
  };

  const handleDelete = async () => {
    if (selectedItem && selectedItem._id) {
      await onDeleteItem(selectedItem._id);
      resetForm();
    }
  };

  // Select the correct form component based on the selected template
  const FormComponent = createFormComponents[selectedTemplate];

  return (
    <div className={styles.card2}>
      <h2>{isEditing ? 'Edit Item' : 'Create Item'}</h2>

      {/* Template selection buttons */}
      <div className={styles.templateButtons}>
        {Object.keys(createFormComponents).map((templateName) => (
          <button
            key={templateName}
            className={`${styles.templateButton} ${selectedTemplate === templateName ? styles.active : ''}`}
            onClick={() => handleTemplateSelect(templateName)}
          >
            {templateName}
          </button>
        ))}
      </div>

      {/* Render the selected form component */}
      {FormComponent && (
        <FormComponent
          initialData={formData}
          onSuccess={handleSubmit}
          isEditing={isEditing}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
}

export default Card2;
