import React, { useState, useEffect } from "react";
import styles from "./Card1.l2.module.css";

function Card1({ dateRange, setDateRange }) {
  const [currentDateTime, setCurrentDateTime] = useState(
    new Date().toLocaleString()
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.card1}>
      <div className={styles.header}>
        <h2>Leads Control Panel</h2>
        <div className={styles.dateTime}>{currentDateTime}</div>
      </div>
      <div className={styles.tabContainer}>
        <button
          className={`${styles.tabButton} ${
            dateRange === "today" ? styles.active : ""
          }`}
          onClick={() => setDateRange("today")}
        >
          Today
        </button>
        <button
          className={`${styles.tabButton} ${
            dateRange === "yesterday" ? styles.active : ""
          }`}
          onClick={() => setDateRange("yesterday")}
        >
          Yesterday
        </button>
        <button
          className={`${styles.tabButton} ${
            dateRange === "thisWeek" ? styles.active : ""
          }`}
          onClick={() => setDateRange("thisWeek")}
        >
          This Week
        </button>
        <button
          className={`${styles.tabButton} ${
            dateRange === "lastWeek" ? styles.active : ""
          }`}
          onClick={() => setDateRange("lastWeek")}
        >
          Last Week
        </button>
        <button
          className={`${styles.tabButton} ${
            dateRange === "thisMonth" ? styles.active : ""
          }`}
          onClick={() => setDateRange("thisMonth")}
        >
          This Month
        </button>
        <button
          className={`${styles.tabButton} ${
            dateRange === "lastMonth" ? styles.active : ""
          }`}
          onClick={() => setDateRange("lastMonth")}
        >
          Last Month
        </button>
        <button
          className={`${styles.tabButton} ${
            dateRange === "custom" ? styles.active : ""
          }`}
          onClick={() => setDateRange("custom")}
        >
          Custom
        </button>
      </div>
    </div>
  );
}

export default Card1;
