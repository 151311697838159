import React, { useState } from 'react';
import styles from './Card2.l2.module.css';

function Card2({ leads }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Pagination logic
  const totalPages = Math.ceil(leads.length / itemsPerPage);
  const displayedLeads = leads.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className={styles.card2}>
      <h2>Leads</h2>
      <table className={styles.leadsTable}>
        <thead>
          <tr>
            <th>Network Name</th>
            <th>IP Address</th>
            <th>Country</th>
            <th>Status</th>
            <th>Campaign Name</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {displayedLeads.length > 0 ? (
            displayedLeads.map((lead, index) => (
              <tr key={index}>
                <td>{lead.network || 'N/A'}</td>
                <td>{lead.ip || 'N/A'}</td>
                <td>{lead.rawData?.countryName || 'N/A'}</td>
                <td>{lead.rawData?.saleStatus || 'N/A'}</td>
                <td>{lead.rawData?.campaignName || 'N/A'}</td>
                <td>{new Date(lead.timestamp).toLocaleDateString()}</td>
                <td>{new Date(lead.timestamp).toLocaleTimeString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className={styles.noData}>
                No leads available for the selected date range.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className={styles.pagination}>
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={currentPage === i + 1 ? styles.active : ''}
          >
            {i + 1}
          </button>
        ))}
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}

export default Card2;
