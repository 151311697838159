// src/components/create/CreateM5.js
import React, { useState } from 'react';
import axios from 'axios';
import styles from './CreateM5.module.css';

const m5Fields = [
  'apiToken', 'offerName', 'domainName', 'apiName', 
  'referralUrl', 'affSub', 'source', 'endpointUrl' // Added endpointUrl
];

function CreateM5({ onCreateNetwork }) {
  const [formData, setFormData] = useState({
    name: '',
    template: 'M5', // Fixed template set to 'M5'
    apiToken: '',
    offerName: '',
    domainName: '',
    apiName: '',
    referralUrl: '',
    affSub: '',
    source: '',
    endpointUrl: '', // New field for endpoint URL
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Endpoint for creating M5 networks
    const createEndpoint = `${process.env.REACT_APP_API_URL}/api/m5/networks/create`;

    try {
      // Create the network
      const createResponse = await axios.post(createEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const newM5 = createResponse.data;

      // Notify parent component of successful creation
      if (onCreateNetwork) onCreateNetwork(newM5);

      // Trigger download using the network's ID
      const downloadEndpoint = `${process.env.REACT_APP_API_URL}/api/m5/networks/${newM5._id}/download`;
      const downloadResponse = await axios.get(downloadEndpoint, { responseType: 'blob' });

      const blob = new Blob([downloadResponse.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${newM5.apiName || 'm5_template'}.zip`;
      link.click();

    } catch (error) {
      console.error('Error creating or downloading M5 configuration', error);
    }
  };

  return (
    <div className={styles.card2}>
      <h2>Create M5 Network</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Network Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Render form fields specific to M5 template */}
        {m5Fields.map(field => (
          <div className={styles.formGroup} key={field}>
            <label>{field}:</label>
            <input
              type="text"
              name={field}
              value={formData[field] || ''}
              onChange={handleChange}
            />
          </div>
        ))}

        <div className={styles.formActions}>
          <button type="submit" className={styles.createButton}>Create</button>
        </div>
      </form>
    </div>
  );
}

export default CreateM5;
