import React, { useState, useEffect, useCallback } from 'react';
import Card1 from './Card1';
import Card2 from './Card2';
import axios from 'axios';
import styles from './MainContent.uas.module.css';

function MainContent({ userAgentOption }) {
  const [data, setData] = useState([]);

  // Fetch user agent data from the backend
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-agents/data`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching user agents', error);
    }
  };

  useEffect(() => {
    // Fetch data on component mount
    fetchData();
  }, []);

  const handleDataUpdate = useCallback(() => {
    // After upload or delete, fetch the data again
    fetchData();
  }, []);

  return (
    <div className={styles.mainContent}>
      <div className={styles.card1Container}>
        {/* Card1 handles the file upload */}
        <Card1 onUpload={handleDataUpdate} />
      </div>
      <div className={styles.card2Container}>
        {/* Card2 displays the user agents */}
        <Card2 data={data} />
      </div>
    </div>
  );
}

export default MainContent;
