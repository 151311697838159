import React from 'react';
import styles from './RightSidebar.ul.module.css';

function RightSidebar() {
  return (
    <div className={styles.rightSidebar}>

    </div>
  );
}

export default RightSidebar;
