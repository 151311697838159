import React, { useState, useEffect, useCallback } from 'react';
import Card1 from './Card1';
import Card2 from './Card2';
import axios from 'axios';
import styles from './MainContent.ul.module.css';

function MainContent() {
  const [data, setData] = useState([]);

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/schedule/data`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching saved data', error);
      }
    };

    fetchData();
  }, []);

  // Handle data update (uploading new data or after deletion)
  const handleDataUpdate = useCallback((newData) => {
    setData(newData); // Update the data state with the new data
  }, []);

  return (
    <div className={styles.mainContent}>
      <div className={styles.card1Container}>
        <Card1 onDelete={handleDataUpdate} onUpload={handleDataUpdate} /> {/* Pass handleDataUpdate for both upload and delete */}
      </div>
      <div className={styles.card2Container}>
        <Card2 data={data} onRowsDeleted={handleDataUpdate} /> {/* Pass handleDataUpdate to update data after rows are deleted */}
      </div>
    </div>
  );
}

export default MainContent;
