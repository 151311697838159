import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import styles from './Card1.Schedule.module.css';

function Card1({ onCountryCodeChange, onUpdateRows, selectedRows, onAssignedProxiesUpdate, onProxyTemplateChange }) {
  const [selectedSection, setSelectedSection] = useState(null);
  const [proxyTemplates, setProxyTemplates] = useState([]);
  const [selectedProxyTemplate, setSelectedProxyTemplate] = useState('');
  const [startingPortGap, setStartingPortGap] = useState(0);
  const [userAgentOption, setUserAgentOption] = useState('custom');
  const [formData, setFormData] = useState({
    offerLink: '',
    countryCode: '',
    taskName: ''
  });
  const [offers, setOffers] = useState([]);
  const [trackboxOffers, setTrackboxOffers] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryRowRanges, setCountryRowRanges] = useState({});
  const [selectedOffer, setSelectedOffer] = useState('');
  const [selectedOfferLink, setSelectedOfferLink] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [result, setResult] = useState('');
  const [scheduleTime, setScheduleTime] = useState({ date: '', time: '' });
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('');
  const [assignedProxies, setAssignedProxies] = useState([]);
  const [availableUserAgents, setAvailableUserAgents] = useState([]);
  const [geoDetails, setGeoDetails] = useState([]);
  const [usedRows, setUsedRows] = useState([]);

  useEffect(() => {
    const fetchOffersAndCountryCodes = async () => {
      try {
        const [offersResponse, trackboxOffersResponse, countryCodesResponse] = await Promise.all([
          axios.get('/api/offers'),
          axios.get('/api/trackboxoffers'),
          axios.get('/api/schedule/data'),
        ]);

        const combinedOffers = [...(offersResponse.data || []), ...(trackboxOffersResponse.data || [])];
        setOffers(combinedOffers);

        const countryRowRanges = {};
        countryCodesResponse.data.forEach((item, index) => {
          const { countryCode } = item;
          if (!countryRowRanges[countryCode]) {
            countryRowRanges[countryCode] = { from: index + 1, to: index + 1 };
          } else {
            countryRowRanges[countryCode].to = index + 1;
          }
        });

        setCountryCodes(Object.keys(countryRowRanges));
        setCountryRowRanges(countryRowRanges);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchOffersAndCountryCodes();
  }, []);

  useEffect(() => {
    if (selectedOfferLink && selectedCountryCode) {
      const fetchUsedRows = async () => {
        try {
          const response = await axios.get(`/api/schedule/rows/used?offerLink=${selectedOfferLink}&countryCode=${selectedCountryCode}`);
          setUsedRows(response.data.usedRows || []);
        } catch (error) {
          console.error('Error fetching used rows:', error);
        }
      };
      fetchUsedRows();
    }
  }, [selectedOfferLink, selectedCountryCode]);

  useEffect(() => {
    const fetchProxyTemplates = async () => {
      try {
        const response = await axios.get('/api/proxy/get-settings');
        const templates = [
          { name: 'Smartproxy', data: response.data.smartproxy },
          { name: 'Oxylabs', data: response.data.oxylabs },
        ];
        setProxyTemplates(templates);
        setGeoDetails(response.data.geoDetails || []);
      } catch (error) {
        console.error('Error fetching proxy settings:', error);
      }
    };

    fetchProxyTemplates();
  }, []);

  useEffect(() => {
    const fetchUserAgents = async () => {
      try {
        const response = await axios.get('/api/user-agents/data');
        setAvailableUserAgents(response.data.filter(agent => !agent.used));
      } catch (error) {
        console.error('Error fetching user agents:', error);
      }
    };

    fetchUserAgents();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCountryCodeChange = async (e) => {
    const countryCode = e.target.value;
    setSelectedCountryCode(countryCode);
    setFormData((prev) => ({ ...prev, countryCode }));

    try {
      const response = await axios.get(`/api/schedule/rows/available?countryCode=${countryCode}`);
      const availableRows = response.data.availableRows || [];
      onUpdateRows(availableRows); // Send the rows to Card2
      onCountryCodeChange(countryCode); // Notify MainContent of the selected country code
    } catch (error) {
      console.error('Error fetching available rows:', error);
      onUpdateRows([]); // Clear rows if there's an error
    }
  };

  const handleOfferChange = (e) => {
    const selectedOffer = offers.find(offer => offer.offerLink === e.target.value);
    setSelectedOffer(e.target.value);
    setSelectedOfferLink(selectedOffer ? selectedOffer.offerLink : '');
  };

  const handleSchedule = async () => {
    try {
      const response = await axios.post('/api/schedule/run-task', {
        taskName: formData.taskName,
        userAgent: userAgentOption,
        proxies: assignedProxies,
        formData: {
          ...formData,
          offerLink: selectedOfferLink,
          countryCode: selectedCountryCode,
        },
        startTime: `${scheduleTime.date}T${scheduleTime.time}:00.000Z`,
      });

      setResult(JSON.stringify(response.data, null, 2));
      setFeedbackMessage('Task scheduled successfully!');
      setFeedbackColor('green');
      setTimeout(() => {
        setFeedbackMessage('');
        setSelectedSection(null);
        resetCard();
      }, 3000);
      const tasksResponse = await axios.get('/api/schedule/tasks');
      setScheduledTasks(tasksResponse.data);
    } catch (error) {
      setFeedbackMessage(`Error scheduling task: ${error.response.data.message}`);
      setFeedbackColor('red');
      setTimeout(() => setFeedbackMessage(''), 3000);
    }
  };

  const resetCard = () => {
    setFormData({
      offerLink: '',
      countryCode: '',
      taskName: ''
    });
    setSelectedOffer('');
    setSelectedOfferLink('');
    setSelectedCountryCode('');
    setAssignedProxies([]);
    setAvailableUserAgents([]);
    setSelectedProxyTemplate('');
    setUserAgentOption('custom');
  };

  const assignProxiesAndUserAgents = async () => {
    const requiredRows = selectedRows.length;

    if (!selectedProxyTemplate) {
      setFeedbackMessage('No proxy template selected.');
      setFeedbackColor('red');
      return;
    }

    const geoDetail = geoDetails.find(geo => geo.geo === selectedCountryCode);
    if (!geoDetail) {
      setFeedbackMessage(`No proxy details available for the country code: ${selectedCountryCode}`);
      setFeedbackColor('red');
      return;
    }

    const proxyData = geoDetail[selectedProxyTemplate.toLowerCase()];
    if (!proxyData) {
      setFeedbackMessage(`No proxy data available for provider: ${selectedProxyTemplate}`);
      setFeedbackColor('red');
      return;
    }

    const proxyTemplateData = proxyTemplates.find(template => template.name === selectedProxyTemplate)?.data;
    if (!proxyTemplateData) {
      setFeedbackMessage(`No proxy template data found for: ${selectedProxyTemplate}`);
      setFeedbackColor('red');
      return;
    }

    let lastProxyPort = proxyData.nextProxy;

    if (availableUserAgents.length < requiredRows) {
      setFeedbackMessage('Not enough user agents available.');
      setFeedbackColor('red');
      return;
    }

    const assignedProxies = [];

    for (let i = 0; i < requiredRows; i++) {
      const proxyUrl = `http://${proxyTemplateData.username}:${proxyTemplateData.password}@${selectedCountryCode.toLowerCase()}.${selectedProxyTemplate.toLowerCase()}.com:${lastProxyPort}`;
      const assignedUserAgent = availableUserAgents[i]; // Assign a user agent

      assignedProxies.push({
        rowId: selectedRows[i], // The ID of the row
        proxyUrl,
        userAgent: assignedUserAgent.userAgent // Assign the user agent
      });

      // Mark the user agent as used in the backend
      try {
        await axios.post('/api/proxy/update-row', {
          proxyUrl,
          userAgent: assignedUserAgent.userAgent,
          isUsed: true // Mark as used
        });

        await axios.post('/api/proxy/user-agents/mark-used', {
          userAgentId: assignedUserAgent._id // Send user agent ID to mark as used
        });
      } catch (error) {
        console.error('Error updating proxy and user agent:', error);
      }

      lastProxyPort += 1;
    }

    // Update proxy details in the backend
    await axios.post('/api/proxy/update-geo-details', {
      geo: selectedCountryCode,
      provider: selectedProxyTemplate.toLowerCase(),
      lastProxyPort: lastProxyPort - 1,
    });

    // Update UI with the newly assigned proxies and user agents
    setAssignedProxies(assignedProxies);
    setFeedbackMessage('Proxies and user agents assigned successfully!');
    setFeedbackColor('green');
    setTimeout(() => setFeedbackMessage(''), 3000);

    onAssignedProxiesUpdate(assignedProxies);
  };

  return (
    <div className={styles.card1}>
      {feedbackMessage && (
        <div className={styles.feedback} style={{ color: feedbackColor }}>
          {feedbackMessage}
        </div>
      )}
      <div className={styles.sectionButtons}>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'createTask' ? styles.active : ''}`}
          onClick={() => setSelectedSection('createTask')}
        >
          Create New Task
        </button>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'offerCountry' ? styles.active : ''}`}
          onClick={() => setSelectedSection('offerCountry')}
        >
          Select Offer and Country
        </button>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'proxyUserAgentSettings' ? styles.active : ''}`}
          onClick={() => setSelectedSection('proxyUserAgentSettings')}
        >
          Proxy and User Agent Settings
        </button>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'scheduleTime' ? styles.active : ''}`}
          onClick={() => setSelectedSection('scheduleTime')}
        >
          Choose Schedule Time
        </button>
      </div>
      <div className={styles.sectionContent}>
        {selectedSection === 'createTask' && (
          <div className={styles.createTaskSection}>
            <div>
              <label>Task Name:</label>
              <input
                type="text"
                name="taskName"
                value={formData.taskName}
                onChange={handleInputChange}
                placeholder="Enter Task Name"
              />
            </div>
          </div>
        )}

        {selectedSection === 'offerCountry' && (
          <div className={styles.offerCountrySection}>
            <div className={styles.twoColumn}>
              <div>
                <label>Offer:</label>
                <select
                  value={selectedOffer}
                  onChange={handleOfferChange}
                >
                  <option value="">Select Offer</option>
                  {offers.map((offer) => (
                    <option key={offer._id} value={offer.offerLink}>
                      {offer.offerName}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>Offer Link:</label>
                <input
                  type="text"
                  value={selectedOfferLink}
                  onChange={(e) => setSelectedOfferLink(e.target.value)}
                  placeholder="Enter or select offer link"
                />
              </div>
            </div>
            <div className={styles.twoColumn}>
              <div>
                <label>Country Code:</label>
                <select
                  value={selectedCountryCode}
                  onChange={handleCountryCodeChange}
                >
                  <option value="">Select Country Code</option>
                  {countryCodes.map((code, index) => (
                    <option key={index} value={code}>
                      {code}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {selectedSection === 'proxyUserAgentSettings' && (
          <div className={styles.proxyUserAgentSettingsSection}>
            <div className={styles.twoColumn}>
              <div>
                <label>Proxy Template:</label>
                <select
                  value={selectedProxyTemplate}
                  onChange={(e) => {
                    setSelectedProxyTemplate(e.target.value);
                    onProxyTemplateChange(e.target.value); // Notify MainContent of the selected proxy template
                    console.log('Selected Proxy Template:', e.target.value);
                  }}
                >
                  <option value="">Choose Template</option>
                  {proxyTemplates.map((template) => (
                    <option key={template.name} value={template.name}>
                      {template.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button className={styles.assignButton} onClick={assignProxiesAndUserAgents}>
              Assign Proxies & User Agents
            </button>
          </div>
        )}

        {selectedSection === 'scheduleTime' && (
          <div className={styles.scheduleTimeSection}>
            <div className={styles.twoColumn}>
              <div>
                <label>Date:</label>
                <input
                  type="date"
                  value={scheduleTime.date}
                  onChange={(e) => setScheduleTime({ ...scheduleTime, date: e.target.value })}
                />
              </div>
              <div>
                <label>Time:</label>
                <input
                  type="time"
                  value={scheduleTime.time}
                  onChange={(e) => setScheduleTime({ ...scheduleTime, time: e.target.value })}
                />
              </div>
            </div>
            <button className={styles.actionButton} onClick={handleSchedule}>Schedule</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Card1;
