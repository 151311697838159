import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from '../../utils/axiosConfig';
import styles from './Card1.ul.module.css';

function Card1({ onUpload }) {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('');

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(`${process.env.REACT_APP_API_URL}/api/schedule/upload`, formData)
        .then((response) => {
          onUpload(response.data.data);
          setFeedbackMessage('File uploaded successfully!');
          setFeedbackColor('green');
          setTimeout(() => setFeedbackMessage(''), 3000);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
          const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
          setFeedbackMessage(`Error uploading file: ${errorMessage}`);
          setFeedbackColor('red');
          setTimeout(() => setFeedbackMessage(''), 3000);
        });
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: '.xlsx, .xls',
  });

  return (
    <div className={styles.card1}>
      {feedbackMessage && (
        <div className={styles.feedback} style={{ color: feedbackColor }}>
          {feedbackMessage}
        </div>
      )}
      <div className={styles.uploadSection}>
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop an Excel file here, or click to select one</p>
        </div>
      </div>
    </div>
  );
}

export default Card1;
