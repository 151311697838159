import React, { useState, useEffect } from 'react';
import Sidebar from '../../dashboard/Sidebar';
import TopMetrics from './TopMetrics';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import Card1 from './Card1';
import Card2 from './Card2'; // Import Card2 for displaying geo details
import axios from '../../utils/axiosConfig';
import styles from './ProxySettings.ps.module.css';

function ProxySettings() {
  const [proxyTemplates, setProxyTemplates] = useState([]);
  const [selectedProxyTemplate, setSelectedProxyTemplate] = useState('');
  const [geoDetails, setGeoDetails] = useState([]);
  const [proxyUsername, setProxyUsername] = useState('');
  const [proxyPassword, setProxyPassword] = useState('');
  const [startingPortGap, setStartingPortGap] = useState(0);

  useEffect(() => {
    const fetchProxyTemplates = async () => {
      try {
        const response = await axios.get('/api/proxy');
        setProxyTemplates(response.data);
      } catch (error) {
        console.error('Error fetching proxy templates:', error);
      }
    };
    fetchProxyTemplates();
  }, []);

  const handleProxyTemplateChange = async (templateId) => {
    setSelectedProxyTemplate(templateId);

    if (templateId) {
      try {
        const response = await axios.get(`/api/proxy/${templateId}`);
        setGeoDetails(response.data.geoDetails); // Update with the geo details from the backend
        setProxyUsername(response.data.username);
        setProxyPassword(response.data.password);
        setStartingPortGap(response.data.startingPortGap);
      } catch (error) {
        console.error('Error fetching geo details:', error);
      }
    }
  };

  const handleSaveProxyTemplate = async () => {
    try {
      await axios.post('/api/proxy/save-settings', {
        templateId: selectedProxyTemplate,
        username: proxyUsername,
        password: proxyPassword,
        startingPortGap,
      });
      // Optional: Show success message or perform another action after save
    } catch (error) {
      console.error('Error saving proxy settings:', error);
    }
  };

  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="top-metrics">
        <TopMetrics />
      </div>
      <div id="main-content">
        <div className={styles.mainContent}>
          <div className={styles.card1Container}>
            <Card1
              proxyTemplates={proxyTemplates}
              selectedProxyTemplate={selectedProxyTemplate}
              proxyUsername={proxyUsername}
              proxyPassword={proxyPassword}
              startingPortGap={startingPortGap}
              setProxyUsername={setProxyUsername}
              setProxyPassword={setProxyPassword}
              setStartingPortGap={setStartingPortGap}
              onProxyTemplateChange={handleProxyTemplateChange}
              onSave={handleSaveProxyTemplate}
            />
          </div>
          <div className={styles.card2Container}>
            <Card2 geoDetails={geoDetails} />
          </div>
        </div>
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default ProxySettings;
