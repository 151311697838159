import React, { useState, useEffect } from 'react';
import styles from './Card1.ps.module.css';
import axios from '../../utils/axiosConfig';  // Import the custom axios instance

function Card1() {
  const [provider, setProvider] = useState('smartproxy');  // State for provider
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [startingPortGap, setStartingPortGap] = useState('');
  const [result, setResult] = useState('');

  // Function to fetch the saved settings when the provider is selected
  const fetchSettings = async (selectedProvider) => {
    try {
      const response = await axios.get('/api/proxy/get-settings');
      const settings = response.data[selectedProvider];

      if (settings) {
        setUsername(settings.username || '');
        setPassword(settings.password || '');
        setStartingPortGap(settings.startingPortGap || '');
      } else {
        // If no settings are found, reset the fields
        setUsername('');
        setPassword('');
        setStartingPortGap('');
      }
    } catch (error) {
      console.error('Error fetching proxy settings:', error);
    }
  };

  // Effect to fetch settings when provider is changed
  useEffect(() => {
    fetchSettings(provider);  // Fetch settings when provider changes
  }, [provider]);

  // Handle the form submission for saving credentials
  const handleSave = async () => {
    try {
      const response = await axios.post('/api/proxy/save-settings', {
        provider,
        username,
        password,
        startingPortGap: parseInt(startingPortGap, 10),
      });

      setResult(`${provider} credentials saved successfully!`);
    } catch (error) {
      console.error('Error saving credentials:', error);
      setResult('Error saving credentials.');
    }
  };

  return (
    <div className={styles.card1}>
      <h2>Proxy Settings</h2>
      {/* Single row for all fields */}
      <div className={styles.singleRow}>
        <div>
          <label>Provider:</label>
          <select
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
          >
            <option value="smartproxy">Smartproxy</option>
            <option value="oxylabs">Oxylabs</option>
          </select>
        </div>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label>Starting Port Gap:</label>
          <input
            type="number"
            value={startingPortGap}
            onChange={(e) => setStartingPortGap(e.target.value)}
          />
        </div>
      </div>
      <button className={styles.saveButton} onClick={handleSave}>
        Save {provider} Proxy Settings
      </button>
      {result && <p>{result}</p>}
    </div>
  );  
}

export default Card1;
