import React, { useEffect, useState, useCallback } from 'react';
import { fetchFTDSData, fetchFTDsWithCRMLead, fetchTrackboxFTDSData, fetchTrackboxFTDsWithCRM } from '../../utils/Trackingapi';
import TopMetricsFTDS from '../../FTDS/TopMetricsFTDS';
import FTDSList from './FTDSList';

const FTDSComponent = () => {
  const [ftds, setFTDS] = useState([]);
  const [dateRange, setDateRange] = useState('today'); // Default to 'today'
  const [ftdType, setFtdType] = useState(''); // Use state for FTD type
  const [selectedColumns, setSelectedColumns] = useState([
    'Click ID', 'Campaign Name', 'Country Name', 'Signup Date', 'Sale Status', 'Network', 'Has FTD', 'Country Code', 'Email', 'Custom5'
  ]);

  const loadFTDSData = useCallback(async () => {
    try {
      const [ftdsData, ftdsWithCRMLeadData, trackboxFTDSData, trackboxFTDsWithCRM] = await Promise.all([
        fetchFTDSData(dateRange, ftdType),
        fetchFTDsWithCRMLead(dateRange, ftdType),
        fetchTrackboxFTDSData(dateRange, ftdType),
        fetchTrackboxFTDsWithCRM(dateRange, ftdType), // Fetch Trackbox FTD with CRM data
      ]);

      // Combine FTD and CRM/Lead data into one entry
      const combinedFTDSData = ftdsData.map(ftd => {
        // Find matching CRM/Lead data by custom5 (clickId)
        const match = ftdsWithCRMLeadData.find(item => item.ftd.ftdData?.custom5 === ftd.ftdData?.custom5);

        // If there's a match, add the email from CRM and any other necessary fields
        if (match) {
          return {
            ...ftd, // Existing FTD data
            crm: match.crm || null, // Include CRM data
            lead: match.lead || null, // Include Lead data if necessary
          };
        }
        return ftd; // Return the FTD as is if no match is found
      });

      // Combine with Trackbox FTD data and merge CRM data if applicable
      const finalCombinedData = [
        // Combine non-Trackbox FTDs with CRM data
        ...combinedFTDSData,
        
        // Combine with Trackbox FTD data and merge CRM data if applicable
        ...trackboxFTDSData.map(ftd => {
          // Find matching Trackbox CRM data by MPC_1
          const trackboxMatch = trackboxFTDsWithCRM.find(item => item.ftd.ftdData?.MPC_1 === ftd.ftdData?.MPC_1);
      
          // If there's a match, update email and other fields from Trackbox CRM
          if (trackboxMatch) {
            return {
              ...ftd, // Existing Trackbox FTD data
              crm: trackboxMatch.crm || ftd.crm, // Update with Trackbox CRM email if available
              lead: trackboxMatch.lead || ftd.lead, // Include Lead data if available
            };
          }
          return ftd; // Return the Trackbox FTD as is if no match is found
        })
      ];
      

      setFTDS(finalCombinedData);
    } catch (error) {
      console.error('Error loading FTDS data:', error);
    }
  }, [dateRange, ftdType]);

  useEffect(() => {
    loadFTDSData();
  }, [loadFTDSData]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadFTDSData();
    }, 30000); // Fetch FTDS data every 30 seconds

    return () => clearInterval(interval);
  }, [loadFTDSData]);

  const handleApplyFilters = (newDateRange, newFtdType, newSelectedColumns) => {
    setDateRange(newDateRange);
    setFtdType(newFtdType);
    setSelectedColumns(newSelectedColumns);
    loadFTDSData(); // Fetch FTDS data based on the new filters
  };

  return (
    <div>
      <TopMetricsFTDS
        dateRange={dateRange}
        setDateRange={setDateRange}
        ftdType={ftdType}
        setFtdType={setFtdType}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        onApplyFilters={handleApplyFilters}
      />
      <h1>FTDS Report</h1>
      <FTDSList ftds={ftds} selectedColumns={selectedColumns} />
    </div>
  );
};

export default FTDSComponent;
