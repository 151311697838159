import React from 'react';
import { formatDateTime } from '../../utils/dateTimeUtils';
import styles from './FTDSList.module.css';

const FTDSList = ({ ftds = [], selectedColumns = [] }) => {
  console.log('FTDs:', ftds);
  console.log('Selected Columns:', selectedColumns);

  if (ftds.length === 0) {
    return <div>No FTDs available.</div>;
  }

  if (selectedColumns.length === 0) {
    return <div>Please select columns to display.</div>;
  }

  const columns = {
    'Click ID': ftd => ftd?.clickId || ftd?.ftdData?.MPC_1 || 'N/A', // Handles both regular and Trackbox Click ID (MPC_1)
    'Campaign Name': ftd => ftd?.ftdData?.campaignName || 'N/A',
    'Country Name': ftd => ftd?.ftdData?.countryName || 'N/A',
    'Signup Date': ftd => ftd?.timestamp ? formatDateTime(ftd.timestamp) : 'N/A',
    'Sale Status': ftd => ftd?.lead?.leadData?.saleStatus || ftd?.ftdData?.saleStatus || ftd?.lead?.leadData?.customerData?.call_status || 'N/A', // Sale status from Lead or FTD
    'Network': ftd => ftd?.network || 'N/A',
    'Has FTD': ftd => ftd?.ftdData?.hasFTD ? 'Yes' : 'No',
    'Country Code': ftd => ftd?.ftdData?.countryCode || 'N/A',
    'Email': ftd => ftd?.crm?.email || ftd?.lead?.leadData?.email || 'N/A', // Email from CRM or Lead data
    'Custom5': ftd => ftd?.ftdData?.custom5 || ftd?.ftdData?.MPC_1 || 'N/A', // Handle Trackbox (MPC_1) and regular FTDs
  };

  return (
    <div className={styles.ftdsList}>
      <table>
        <thead>
          <tr>
            {selectedColumns.map(column => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ftds.map((ftd, index) => (
            <tr key={ftd?._id || index}>
              {selectedColumns.map(column => (
                <td key={column}>
                  {columns[column] ? columns[column](ftd) : 'N/A'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FTDSList;
